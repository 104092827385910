<template>
  <div>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div
        class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-3"
      >
        <div class="d-flex flex-column justify-content-center">
          <h4 class="mb-1 mt-3"></h4>
        </div>
        <div class="d-flex align-content-center flex-wrap gap-3">
          <button type="submit" class="btn btn-primary" @click="showMsgBox()">
            Enregistrer
          </button>
        </div>
      </div>
      <div class="row">
        <!-- First column-->
        <div class="col-12 col-lg-8">
          <div class="card mb-4">
            <div class="card-header">
              <h3 class="card-tile mb-0">
                Détails Commande n° <span> {{ commandeInfo.id }} </span>
              </h3>
            </div>
            <div class="card-body">
              <div class="mb-3">
                <h5 class="form-label">Date création</h5>
                <b-form-datepicker
                  id="datepicker-dateformat2"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  v-model="commandeInfo.date_created"
                  locale="fr"
                ></b-form-datepicker>
              </div>

              <div
                class="v-card-text d-flex flex-wrap justify-space-between flex-column flex-sm-row print-row"
              >
                <div my="4">
                  <h5 class="text-sm font-weight-semibold mb-1">
                    facturation
                    <feather-icon
                      @click="activateFacturationForm()"
                      icon="EditIcon"
                      class="mx-1"
                    />
                  </h5>
                  <div v-if="isFacturationActive == false">
                    <p class="mb-1">
                      {{ billingInfo.last_name }}
                      {{ billingInfo.first_name }}
                    </p>
                    <p class="mb-1">{{ billingInfo.address_1 }}</p>
                    <p>
                      {{ billingInfo.city }}, {{ billingInfo.state }}
                      {{ billingInfo.postcode }}
                    </p>
                  </div>
                </div>
                <div class="expedition" my="4">
                  <h5 class="text-sm font-weight-semibold mb-1">
                    expédition
                    <feather-icon
                      icon="EditIcon"
                      class="mx-1"
                      @click="activateExpeditionForm()"
                    />
                  </h5>
                  <div v-if="isExpeditionActive == false">
                    <p class="mb-1">
                      {{ shippingInfo.last_name }} {{ shippingInfo.first_name }}
                    </p>
                    <p class="mb-1">{{ shippingInfo.address_1 }}</p>
                    <p>
                      {{ shippingInfo.city }}, {{ shippingInfo.state }}
                      {{ shippingInfo.postcode }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="row">
                <!-- First column-->
                <div class="col-12 col-lg-6">
                  <div class="card-body" v-if="isFacturationActive == true">
                    <div class="row mb-3">
                      <div class="col">
                        <div class="card-header" style="padding: 0.5rem">
                          <h5 class="form-label">Nom</h5>
                        </div>
                        <div>
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Nom"
                            required
                            v-model="commandeInfo.billing.last_name"
                          />
                        </div>
                      </div>

                      <div class="col">
                        <div class="card-header" style="padding: 0.5rem">
                          <h5 class="form-label">Prénom</h5>
                        </div>
                        <div>
                          <input
                            class="form-control"
                            type="text"
                            v-model="billingInfo.first_name"
                            placeholder="Prénom"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <h5 class="form-label">Entreprise</h5>
                      <input
                        class="form-control"
                        type="text"
                        placeholder="Entreprise"
                        v-model="billingInfo.company"
                        required
                      />
                    </div>

                    <div class="row mb-3">
                      <div class="col">
                        <div class="card-header" style="padding: 0.5rem">
                          <h5 class="form-label">Adresse ligne 1</h5>
                        </div>
                        <div>
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Adresse ligne 1"
                            v-model="billingInfo.address_1"
                            required
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="card-header" style="padding: 0.5rem">
                          <h5 class="form-label">Adresse ligne 2</h5>
                        </div>
                        <div>
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Adresse ligne 2"
                            v-model="billingInfo.address_2"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col">
                        <div class="card-header" style="padding: 0.5rem">
                          <h5 class="form-label">Ville</h5>
                        </div>
                        <div>
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Ville"
                            v-model="billingInfo.city"
                            required
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="card-header" style="padding: 0.5rem">
                          <h5 class="form-label">Code postal</h5>
                        </div>
                        <div>
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Code postal"
                            v-model="billingInfo.postcode"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col">
                        <div class="card-header" style="padding: 0.8rem">
                          <h5 class="form-label">Pays/Région</h5>
                        </div>
                        <div>
                          <input
                            class="form-control"
                            type="text"
                            v-model="billingInfo.country"
                            placeholder="Pays/Région"
                            required
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="card-header" style="padding: 0.1rem">
                          <h5 class="form-label">Région / Département</h5>
                        </div>
                        <div>
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Région/Département"
                            v-model="billingInfo.state"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col">
                        <!-- email -->
                        <div class="card-header" style="padding: 0.5rem">
                          <h5 class="form-label">Email</h5>
                        </div>
                        <validation-provider
                          #default="{ errors }"
                          name="Email"
                          rules="email"
                        >
                          <b-form-input
                            id="login-email"
                            v-model="billingInfo.email"
                            :state="errors.length > 0 ? false : null"
                            name="login-email"
                            placeholder="john@example.com"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                      <div class="col">
                        <div class="card-header" style="padding: 0.5rem">
                          <h5 class="form-label">Téléphone</h5>
                        </div>
                        <div>
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Numero Téléphone"
                            v-model="billingInfo.phone"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="card-header" style="padding: 0.5rem">
                        <h5 class="form-label">Mode de paiement</h5>
                      </div>

                      <b-form-select
                        class="form-control"
                        type="text"
                        :options="modePaiement"
                        placeholder="Mode de paiement"
                        required
                        v-model="commandeInfo.payment_method_title"
                      />
                    </div>
                    <div class="mb-3">
                      <div class="card-header" style="padding: 0.5rem">
                        <h5 class="form-label">Id transaction</h5>
                      </div>
                      <div>
                        <input
                          class="form-control"
                          type="text"
                          placeholder="Transaction ID"
                          v-model="commandeInfo.transaction_id"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="card-body" v-if="isExpeditionActive == true">
                    <div class="row mb-3">
                      <div class="col">
                        <div class="card-header" style="padding: 0.5rem">
                          <h5 class="form-label">Nom</h5>
                        </div>
                        <div>
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Nom"
                            required
                            v-model="shippingInfo.last_name"
                          />
                        </div>
                      </div>

                      <div class="col">
                        <div class="card-header" style="padding: 0.5rem">
                          <h5 class="form-label">Prénom</h5>
                        </div>
                        <div>
                          <input
                            class="form-control"
                            type="text"
                            v-model="shippingInfo.first_name"
                            placeholder="Prénom"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <h5 class="form-label">Entreprise</h5>
                      <input
                        class="form-control"
                        type="text"
                        placeholder="Entreprise"
                        v-model="shippingInfo.company"
                        required
                      />
                    </div>

                    <div class="row mb-3">
                      <div class="col">
                        <div class="card-header" style="padding: 0.5rem">
                          <h5 class="form-label">Adresse ligne 1</h5>
                        </div>
                        <div>
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Adresse ligne 1"
                            v-model="shippingInfo.address_1"
                            required
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="card-header" style="padding: 0.5rem">
                          <h5 class="form-label">Adresse ligne 2</h5>
                        </div>
                        <div>
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Adresse ligne 2"
                            v-model="shippingInfo.address_2"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col">
                        <div class="card-header" style="padding: 0.5rem">
                          <h5 class="form-label">Ville</h5>
                        </div>
                        <div>
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Ville"
                            v-model="shippingInfo.city"
                            required
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="card-header" style="padding: 0.5rem">
                          <h5 class="form-label">Code postal</h5>
                        </div>
                        <div>
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Code postal"
                            v-model="shippingInfo.postcode"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col">
                        <div class="card-header" style="padding: 0.8rem">
                          <h5 class="form-label">Pays/Région</h5>
                        </div>
                        <div>
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Pays/Région"
                            v-model="shippingInfo.country"
                            required
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="card-header" style="padding: 0.1rem">
                          <h5 class="form-label">Région / Département</h5>
                        </div>
                        <div>
                          <input
                            class="form-control"
                            type="text"
                            v-model="shippingInfo.state"
                            placeholder="Région/Departement"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="card-header" style="padding: 0.5rem">
                        <h5 class="form-label">Téléphone</h5>
                      </div>
                      <div>
                        <input
                          class="form-control"
                          type="text"
                          placeholder="Numero Téléphone"
                          v-model="shippingInfo.phone"
                          required
                        />
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="card-header" style="padding: 0.5rem">
                        <h5 class="form-label">Note fournie par le client</h5>
                      </div>
                      <div>
                        <b-form-textarea
                          id="textarea-no-resize"
                          placeholder="Note du client à propos de la commande"
                          rows="3"
                          no-resize
                          v-model="commandeInfo.customer_note"
                        ></b-form-textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="card mb-4">
            <div class="card-header">
              <h3 class="card-tile mb-0">Status de la commande</h3>
            </div>
            <div class="card-body">
              <div class="mb-3">
                <h5 class="form-label">Status</h5>
                <v-select
                  style="cursor: pointer"
                  placeholder="Status"
                  :options="status"
                  label="text"
                  input-id="value"
                  value="value"
                  v-model="commandeInfo.status"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-8">
          <div class="card mb-4">
            <div class="card-header">
              <h3 class="card-tile mb-0">Article</h3>
            </div>

            <b-table
              :items="productInfo"
              :fields="fields"
              striped
              hover
              responsive
              :busy="isBusy"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong style="margin-left: 5px">Loading...</strong>
                </div>
              </template>
              <template #cell(actions)="data">
                <div class="text-nowrap">
                  <feather-icon
                    :id="`invoice-row-${data.item.id}-edit-icon`"
                    icon="EditIcon"
                    class="mx-1"
                    @click="getInfo(data.item)"
                  />
                  <b-tooltip
                    title="Modification"
                    placement="right"
                    :target="`invoice-row-${data.item.id}-edit-icon`"
                  />
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
      <EditQuantiteProduct
        v-if="showModal"
        :showModal="showModal"
        :quantity="info.quantity"
        :total="info.total"
        :id="info.id"
        :price="info.price"
        @ConfirmEdit="changeQuantity"
      />
    </b-overlay>
  </div>
</template>
<script>
import {
  BFormDatepicker,
  BOverlay,
  VBTooltip,
  BFormInput,
  BFormSelect,
  BTable,
  BTooltip,
  BSpinner,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "axios";
import websiteURL from "@/websiteURL";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { email } from "@validations";
import EditQuantiteProduct from "../Commandes/EditQuantiteProduct.vue";
export default {
  components: {
    BFormDatepicker,
    BOverlay,
    VBTooltip,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormSelect,
    BTooltip,
    BTable,
    EditQuantiteProduct,
    BSpinner,
    BFormTextarea,
  },

  data() {
    return {
      commandeInfo: [],
      billingInfo: [],
      shippingInfo: [],
      productInfo: [],
      status: [
        { value: "pending", text: "Attente paiement" },
        { value: "processing", text: "En cours" },
        { value: "on-hold", text: "En attente" },
        { value: "completed", text: "Terminée" },
        { value: "cancelled", text: "Annulée" },
        { value: "refunded", text: "Remboursée" },
        { value: "failed", text: "Échouée" },
      ],
      modePaiement: [
        "Paiement à la livraison",
        "Virement bancaire",
        "Paiements par cheque",
        "Autre",
      ],
      fields: [
        {
          label: "Article",
          key: "name",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Cout",
          key: "price",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Quantité",
          key: "quantity",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Total",
          key: "total",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Action",
          key: "actions",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      isFacturationActive: false,
      isExpeditionActive: false,
      email,
      showModal: false,
      info: [],
      loading: false,
      isBusy: false,
      statusValue: {},
      boxMsg: "",
    };
  },
  async beforeRouteEnter(to, from, next) {
    const apiUrl = websiteURL.WEBSITE + `wp-json/wc/v3/orders/${to.params.id}`;
    const consumerKey = websiteURL.CONSUMERKEY;
    const consumerSecret = websiteURL.CONSUMERSECRET;
    try {
      const response = await axios.get(apiUrl, {
        auth: {
          username: consumerKey,
          password: consumerSecret,
        },
      });
      next((vm) => {
        vm.commandeInfo = response.data;
        vm.billingInfo = vm.commandeInfo.billing;
        vm.shippingInfo = vm.commandeInfo.shipping;
        vm.productInfo = vm.commandeInfo.line_items;
        switch (vm.commandeInfo.status) {
          case "pending":
            {
              vm.statusValue.value = vm.commandeInfo.status;
              vm.statusValue.text = "Attente paiement";
            }
            break;
          case "processing":
            {
              vm.statusValue.value = vm.commandeInfo.status;
              vm.statusValue.text = "En cours";
            }
            break;
          case "on-hold":
            {
              vm.statusValue.value = vm.commandeInfo.status;
              vm.statusValue.text = "En attente";
            }
            break;
          case "completed":
            {
              vm.statusValue.value = vm.commandeInfo.status;
              vm.statusValue.text = "Terminée";
            }
            break;
          case "cancelled":
            {
              vm.statusValue.value = vm.commandeInfo.status;
              vm.statusValue.text = "Annulée";
            }
            break;
          case "refunded":
            {
              vm.statusValue.value = vm.commandeInfo.status;
              vm.statusValue.text = "Remboursée";
            }
            break;
          case "failed":
            {
              vm.statusValue.value = vm.commandeInfo.status;
              vm.statusValue.text = "Échouée";
            }
            break;
        }
        vm.commandeInfo.status = vm.statusValue;
      });
    } catch (error) {
      alert("Une erreur s'est produite, veuillez réessayer plus tard", error);
      next(null);
    }
  },
  methods: {
    async getInfo(item) {
      this.info = item;
      this.showModal = !this.showModal;
    },
    //Permet d'afficher ou non le formulaire de facturation
    activateFacturationForm() {
      if (this.isFacturationActive == false) {
        this.isFacturationActive = true;
      } else {
        this.isFacturationActive = false;
      }
    },
    //Permet d'afficher ou non le formulaire d'expedition
    activateExpeditionForm() {
      if (this.isExpeditionActive == false) {
        this.isExpeditionActive = true;
      } else {
        this.isExpeditionActive = false;
      }
    },
    //Permet la modification d'une commande
    async editCommande() {
      const apiUrl =
        websiteURL.WEBSITE + `wp-json/wc/v3/orders/${this.commandeInfo.id}`;
      const consumerKey = websiteURL.CONSUMERKEY;
      const consumerSecret = websiteURL.CONSUMERSECRET;
      const data = {
        date_created: this.commandeInfo.date_created,
        billing: {
          first_name: this.billingInfo.first_name,
          last_name: this.billingInfo.last_name,
          address_1: this.billingInfo.address_1,
          address_2: this.billingInfo.address_2,
          city: this.billingInfo.city,
          company: this.billingInfo.company,
          country: this.billingInfo.country,
          email: this.billingInfo.email,
          phone: this.billingInfo.phone,
          postcode: this.billingInfo.postcode,
          state: this.billingInfo.state,
        },
        shipping: {
          first_name: this.shippingInfo.first_name,
          last_name: this.shippingInfo.last_name,
          address_1: this.shippingInfo.address_1,
          address_2: this.shippingInfo.address_2,
          city: this.shippingInfo.city,
          company: this.shippingInfo.company,
          country: this.shippingInfo.country,
          phone: this.shippingInfo.phone,
          postcode: this.shippingInfo.postcode,
          state: this.shippingInfo.state,
        },
        status: this.commandeInfo.status.value,
        payment_method_title: this.commandeInfo.payment_method_title,
        transaction_id: this.commandeInfo.transaction_id,
        customer_note: this.commandeInfo.customer_note,
      };
      try {
        const response = await axios.put(apiUrl, data, {
          auth: {
            username: consumerKey,
            password: consumerSecret,
          },
        });
        this.makeToastMsg("info");
      } catch (error) {
        // Handle errors, e.g., show an error message
        alert(
          "Erreur lors de la mise à jour des informations, veuillez réessayer plus tard :",
          error
        );
      }
    },
    //Permet de modifier la quantité d'un produit
    async changeQuantity(info) {
      this.isBusy = true;
      const apiUrl =
        websiteURL.WEBSITE + `wp-json/wc/v3/orders/${this.commandeInfo.id}`;
      const consumerKey = websiteURL.CONSUMERKEY;
      const consumerSecret = websiteURL.CONSUMERSECRET;
      const data = {
        line_items: [
          { quantity: info.quantity, id: info.id, total: String(info.total) },
        ],
      };
      try {
        const response = await axios.put(apiUrl, data, {
          auth: {
            username: consumerKey,
            password: consumerSecret,
          },
        });
        location.reload();
        this.isBusy = false;
      } catch (error) {
        // Handle errors, e.g., show an error message
        alert(
          "Erreur lors de la mise à jour des informations, veuillez réessayer plus tard :",
          error
        );
      }
    },
    //Message pour confirmer ou non la modification d'une commande
    showMsgBox() {
      this.boxMsg = "";
      this.$bvModal
        .msgBoxConfirm("Êtes-vous sûr de vouloir modifier la commande ?", {
          title: "Confirmation",
          size: "md",
          okVariant: "primary",
          okTitle: this.loading ? "Loading..." : "Confirmer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          this.boxMsg = value;
          if (value) {
            if (value) {
              this.loading = true;
              this.editCommande().then(async () => {
                this.loading = false;
              });
            }
          }
        });
    },
    makeToastMsg(variant = null) {
      this.$bvToast.toast("La commande a bien été modifier", {
        title: `Commande ${variant || "default"}`,
        variant,
        solid: true,
        href: this.permalink,
      });
    },
  },
};
</script>
<style lang="scss">
.expedition {
  position: absolute;
  right: 100px;
}
@import "@core/scss/vue/libs/vue-select.scss";
</style>
