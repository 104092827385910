<template>
  <b-modal
    v-model="editShowModal"
    ok-title="Confirmer"
    cancel-title="Annuler"
    @ok="confimrEdit"
    title="Modification rapide"
  >
    <b-row class="my-1">
      <b-col md="3">
        <label for="input-small" style="font-weight: bold">Quantité</label>
      </b-col>

      <b-col md="9">
        <b-form-input
          id="input-small"
          v-model="editQuantity"
          @change="CalculCoutTotal()"
          size="md"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row class="my-1">
      <b-col sm="3">
        <label for="input-small" style="font-weight: bold">Cout</label>
      </b-col>

      <b-col sm="9">
        <b-form-input
          readonly
          id="input-small"
          v-model="editPrice"
          aria-readonly
          size="md"
        ></b-form-input>
      </b-col>
    </b-row>

    <b-row class="my-1">
      <b-col sm="3">
        <label for="input-small" style="font-weight: bold">Total</label>
      </b-col>

      <b-col sm="9">
        <b-form-input
          readonly
          id="input-small"
          v-model="editTotal"
          aria-readonly
          size="md"
        ></b-form-input>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { BRow, BCol, BFormInput, BFormSelect } from "bootstrap-vue";
export default {
  data() {
    return {
      editShowModal: this.showModal,
      editQuantity: this.quantity,
      editTotal: this.total,
      editPrice: this.price,
    };
  },
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormSelect,
  },
  props: {
    showModal: Boolean,
    quantity: Number,
    total: String,
    id: Number,
    price: Number,
  },
  methods: {
    confimrEdit() {
      this.$emit("ConfirmEdit", {
        quantity: this.editQuantity,
        id: this.id,
        total: this.editTotal,
        price: this.price,
      });
    },
    CalculCoutTotal() {
      this.editTotal = this.editPrice * this.editQuantity;
    },
  },
};
</script>
